import { IC_PLAY } from '@/assets/icons';
import { IM_APP_STORE, IM_GOOGLE_PLAY, IM_HOW_TO_VIDEO } from '@/assets/images';
import { ActionBlockData } from '@/services/types/blocksTypes';
import cls from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import styles from './HowToOrderVideo.module.scss';
import { map } from 'lodash';
import YouTubeModal from '@/components/atoms/YouTubeModal';
interface HowToOrderVideoProps {
	className?: string;
	data?: ActionBlockData | any;
}
function convertLineBreaksToArray(str) {
	return str.split(/\r?\n/).filter((line) => line.trim() !== ''); // Remove empty or whitespace-only lines
}

const HowToOrderVideo = ({ className, data }: HowToOrderVideoProps) => {
	const linedata = convertLineBreaksToArray(data?.subtitle);
	return (
		<div className={cls('how_to_order_video', className, styles.how_to_order)}>
			<div className='container'>
				<div className='flex gap-10'>
					<div className='col-12 col-md-6'>
						<div className='how_to_order_video__content'>
							<div className='text-30 fw-400 text-gray-900 mb-20'>{data.title}</div>
							<ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
								{map(linedata, (item, index) => (
									<li className='mb-10' key={index}>
										{item}
									</li>
								))}
							</ul>
							<p className='my-40 text-18 fw-400 text-gray-900'>{data.alt}</p>

							<div className='d-flex gap-10'>
								<Link href={data?.link_parameter?.playstore_link} target='_blank' rel='noopener noreferrer'>
									<Image src={IM_GOOGLE_PLAY} alt='how to order video' />
								</Link>
								<Link href={data?.link_parameter?.appstore_link} target='_blank' rel='noopener noreferrer'>
									<Image src={IM_APP_STORE} alt='how to order video' />
								</Link>
							</div>
						</div>
					</div>

					<div className='col-12 col-md-6'>
						<YouTubeModal videoKey={data?.yt_key}>
							<div className={styles.videoWrapper}>
								<Image
									priority
									src={IM_HOW_TO_VIDEO}
									alt='how to order video'
									quality={100}
									style={{
										width: '100%',
										aspectRatio: 643 / 412
									}}
								/>

								<div className={styles.playButton}>
									<span className='text-30 text-white'>
										<span className='text-30 text-white'>
											<IC_PLAY />
										</span>
									</span>
								</div>
							</div>
						</YouTubeModal>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HowToOrderVideo;
