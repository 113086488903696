import { Block } from '@/services/types/blocksTypes';
import generateBlockLink from '@/services/utils/LinkGenerator';
import {
	DownloadSection,
	HomeBannerImage,
	HomeBannerImageContainer,
	ReferAndEarn
} from '../organisms/Dynamics/Banners';

interface BannerBlockProps {
	data: Block;
	sl?: any;
}

const BannerBlock: React.FC<BannerBlockProps> = ({ data }) => {
	const { block_display_type, block_data } = data;
	const blockLinks = generateBlockLink(data);
	if (block_display_type === 'home_banner_body') {
		return <ReferAndEarn data={block_data} blockLinks={blockLinks} />;
	}
	if (block_display_type === 'home_banner_image') {
		return <HomeBannerImage data={block_data} blockLinks={blockLinks} />;
	}
	if (block_display_type === 'home_banner_image_container') {
		return <HomeBannerImageContainer data={block_data} blockLinks={blockLinks} />;
	}
	if (block_display_type === 'download_our_app') {
		return <DownloadSection data={data} />;
	}
};

export default BannerBlock;
