import { Block } from '@/services/types/blocksTypes';
import generateBlockLink from '@/services/utils/LinkGenerator';
import {
	Action2ColumnHorizontal,
	ActionHorizontal,
	ActionSideScrollUnderline,
	ActionVertical,
	ActionVerticalSideScroll,
	ActionsCategorySideScroll,
	HealthSupplements,
	MostLoveBrands,
	MostSearchOnAroggaBeauty,
	ShopByCategory,
	SidescrollUpToMoneyRange,
	TopDeals
} from '../organisms/Dynamics/Actions';
import Action6ColumnGrid from '../organisms/Dynamics/Actions/Action6ColumnGrid';
import Action8ColumnGrid from '../organisms/Dynamics/Actions/Action8ColumnGrid';

interface Props {
	data: Block;
	sl?: any;
}

const ActionsBlock: React.FC<Props> = ({ data }) => {
	const { block_data, block_display_type, block } = data;
	const blockLinks = generateBlockLink(data);

	if (block_display_type === 'action_horizontal') {
		return <ActionHorizontal data={block_data} label={block} blockLinks={blockLinks} />;
	}
	if (block_display_type === 'action_6_column_grid') {
		return (
			<Action6ColumnGrid
				data={block_data}
				label={block}
				blockLinks={blockLinks}
				block_color_code={data.block_color_code}
			/>
		);
	}
	if (block_display_type === 'action_8_column_grid') {
		return <Action8ColumnGrid data={block_data} label={block} blockLinks={blockLinks} />;
	}
	if (block_display_type === 'action_vertical') {
		return <ActionVertical data={block_data} label={block} blockLinks={blockLinks} />;
	}
	if (block_display_type === 'most_love_brands') {
		return <MostLoveBrands data={block_data} label={block} blockLinks={blockLinks} />;
	}
	if (block_display_type === 'actions_category_side_scroll') {
		return <ActionsCategorySideScroll data={block_data} label={block} blockLinks={blockLinks} />;
	}
	if (block_display_type === 'action_vertical_side_scroll') {
		return (
			<ActionVerticalSideScroll
				data={block_data}
				label={block}
				blockLinks={blockLinks}
				block_color_code={data.block_color_code}
			/>
		);
	}
	if (block_display_type === 'action_2_column_horizontal') {
		return <Action2ColumnHorizontal data={block_data} label={block} blockLinks={blockLinks} />;
	}
	if (block_display_type === 'health_supplements') {
		return <HealthSupplements data={block_data} label={block} blockLinks={blockLinks} />;
	}
	if (block_display_type === 'action_side_scroll_underline') {
		return <ActionSideScrollUnderline data={block_data} label={block} blockLinks={blockLinks} />;
	}
	if (block_display_type === 'shop_by_category') {
		return (
			<ShopByCategory
				data={block_data}
				label={block}
				blockLinks={blockLinks}
				block_color_code={data.block_color_code}
			/>
		);
	}
	if (block_display_type === 'top_deals') {
		return <TopDeals data={block_data} label={block} blockLinks={blockLinks} />;
	}
	if (block_display_type === 'most_search_on_arogga_beauty') {
		return <MostSearchOnAroggaBeauty data={block_data} label={block} blockLinks={blockLinks} />;
	}
	if (block_display_type === 'sidescroll_up_to_money_range') {
		return <SidescrollUpToMoneyRange data={block_data} label={block} blockLinks={blockLinks} />;
	}
};

export default ActionsBlock;
