import Slider from '@/components/molecules/Slider';
import { getImage } from '@/services/utils/helper';
import cls from 'classnames';
import { map } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import styles from './styles.module.scss';

const BeautyTools = ({ data, label, color_code, sub_title, blockLinks }) => {
	const { children } = blockLinks;
	return (
		<div
			className={cls('py-30 ', styles.beauty_tools)}
			style={{
				backgroundColor: `${color_code}`
			}}>
			<div className='container product-slider'>
				<div className='d-flex flex-column align-items-center gap-10'>
					<div className='text-30 text-grey900 fw-600'>{label}</div>
					<div className='text-18 text-error'>{sub_title}</div>
				</div>

				<div className='my-10  mt-10 items-center'>
					<Slider slidesPerView={5.5} spaceBetween={20}>
						{map(data, (d: any, i: number) => (
							<Link href={children[i]?.link || '/'} key={i}>
								<div className={styles.card}>
									<div className={styles.card__image}>
										<Image
											alt={d?.title}
											src={getImage(d.file)}
											width={264}
											height={264}
											priority
											unoptimized
										/>
									</div>

									<div className={styles.category_card__content}>
										<div className='text-grey900 fw-600 mb-10'>{d?.title}</div>
									</div>
								</div>
							</Link>
						))}
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default BeautyTools;
