import Button from '@/components/atoms/Button';
import { getImage } from '@/services/utils/helper';
import Link from 'next/link';
function convertToJSXArray(inputStr) {
	const parts = inputStr.split('|').flatMap((part, idx) => {
		if (idx !== 0) {
			return ['\n', ...part.split('#')];
		}
		return part.split('#');
	});

	// Iterate through the split string and wrap every second element with the desired tag
	const jsxParts = [];
	for (let i = 0; i < parts.length; i++) {
		if (parts[i] === '\n') {
			jsxParts.push('LineBreak');
		} else if (i % 2 === 1) {
			jsxParts.push('<span class="text-error fw-600">' + parts[i] + '</span>');
		} else {
			jsxParts.push('<span class="text-grey900 text-20 fw-400">' + parts[i] + '</span>');
		}
	}

	return jsxParts;
}

export const ReferAndEarn = ({ data, blockLinks }) => {
	return (
		<div
			style={{
				backgroundImage: `url(${getImage(data.file)})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				height: '500px'
			}}>
			<div
				className='container'
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '100%'
				}}>
				<div className='text-grey900 text-white fw-600 text-50 mb-20'>{data.title}</div>
				<div className='text-grey900 text-white  text-18 line-height-27' style={{ maxWidth: '430px' }}>
					{convertToJSXArray(data.subtitle).map((part, idx) => {
						if (part === 'LineBreak') {
							return <div className='mb-5' key={idx} />;
						} else {
							return (
								<span
									key={idx}
									dangerouslySetInnerHTML={{
										__html: part || '<div></div>'
									}}
								/>
							);
						}
					})}
				</div>
				<Link href={blockLinks.bannerLink || '/'}>
					<Button
						style={{
							width: '200px',
							marginTop: '70px',
							height: '58px'
						}}
						className='text-18 text-white fw-500 '
						label='Start Referring'
						type='primary'
					/>
				</Link>
			</div>
		</div>
	);
};

export default ReferAndEarn;
