import { ActionBlockData } from '@/services/types/blocksTypes';
import { getImage } from '@/services/utils/helper';
import cls from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styles from './styles.module.scss';

interface ActionsProps {
	data: ActionBlockData[];
	label: string;
	blockLinks?: any;
}

const Action8ColumnGrid: React.FC<ActionsProps> = ({ data, label, blockLinks }) => {
	const { link, children } = blockLinks;
	return (
		<div className={cls('py-30', styles.category_card_wrapper)}>
			<div className='container product-slider'>
				<div className='d-flex justify-space-between items-center mb-30'>
					<h2 className='text-30 text-grey900 fw-600 mb-0'>{label}</h2>
				</div>
				<div className={styles.card_grid}>
					{data?.map((d: ActionBlockData, i: number) => {
						return (
							<div className='d-flex flex-column' key={i}>
								<div className={styles.category_card}>
									<Link
										href={children[i]?.link}
										key={i}
										style={{
											borderRadius: 12
										}}>
										<div
											className='hover-border-primary border-1-tranparent'
											style={{
												backgroundColor: `${d?.color_code}`,
												borderRadius: 12
											}}>
											<div className={styles.category_card__image}>
												<Image
													unoptimized
													alt={d?.alt}
													src={getImage(d.file)}
													width={125}
													height={125}
													priority
												/>
											</div>
										</div>
									</Link>
								</div>
								<div className={styles.category_card__content}>
									<Link
										className='hover-text-primary'
										href={children[i]?.link}
										key={i}
										style={{
											borderRadius: 12
										}}>
										{d?.title}
									</Link>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Action8ColumnGrid;
