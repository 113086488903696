import Slider from '@/components/molecules/Slider';
import { ActionBlockData } from '@/services/types/blocksTypes';
import { checkIsValidColor, getImage } from '@/services/utils/helper';
import cls from 'classnames';
import Image from 'next/image';
import React from 'react';
import styles from './styles.module.scss';
import Link from 'next/link';
import Button from '@/components/atoms/Button';

interface ActionsProps {
	data: ActionBlockData[];
	label: string;
	blockLinks?: any;
	block_color_code?: string;
}

const ShopByCategory: React.FC<ActionsProps> = ({ data, label, blockLinks, block_color_code }) => {
	const { children, link } = blockLinks;
	return (
		<div className={cls('pb-30 ', styles.shop_by_category)}>
			<div className='container product-slider'>
				{/* <div
					className="text-center "
					style={{
						textAlign: "center",
						marginBottom: 30,
					}}>
					<h2 className="text-gray900 text-30 fw-600">{label}</h2>
				</div> */}
				<div className='d-flex justify-space-between items-center mb-30'>
					<div className='text-30 text-grey900 fw-600'>{label}</div>
					<Link href={link || '/'}>
						<div
							className='text-20 text-grey900 fw-700'
							style={{
								color: checkIsValidColor(block_color_code) ? block_color_code : ''
							}}>
							see all
						</div>
					</Link>
				</div>
				<Slider slidesPerView={6.5} spaceBetween={20}>
					{data?.map((d: ActionBlockData, i: number) => {
						return (
							<div className={styles.card} key={i}>
								<Link href={children[i]?.link}>
									<div
										style={{
											background: `linear-gradient(180deg, ${d?.color_code} 0%, rgba(208, 230, 232, 0.00) 100%)`,
											borderRadius: 12
										}}>
										<div className={styles.card__image}>
											<Image alt='' src={getImage(d.file)} width={110} height={110} priority />
										</div>
									</div>
									<div className={styles.category_card__content}>
										<div className='text-black text-grey700 fw-600'>{d?.title}</div>
										<div className='text-primary mb-5'>{d.subtitle}</div>
									</div>
								</Link>
							</div>
						);
					})}
				</Slider>
			</div>
		</div>
	);
};

export default ShopByCategory;
