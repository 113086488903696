import React from 'react';
import Image from 'next/image';
import { getImage } from '@/services/utils/helper';
import Link from 'next/link';
import Slider from '@/components/molecules/Slider';

interface Props {
	data: any;
	blockLinks: any;
}
const TitleTop: React.FC<Props> = ({ data, blockLinks }) => {
	const { link } = blockLinks;

	return (
		<div className='container my-5'>
			<Slider>
				{data?.block_page_banner?.map((d: any, i: number) => {
					return (
						<div key={i}>
							<Link href={link || '/'} passHref={true}>
								<Image
									className='cursor-pointer'
									alt=''
									src={d.src}
									width={1000}
									height={300}
									style={{
										height: '100%',
										width: '100%',
										borderRadius: '10px'
									}}
									unoptimized
								/>
							</Link>
						</div>
					);
				})}
			</Slider>
		</div>
	);
};

export default TitleTop;
