import React from 'react';

import HowToOrderVideo from '../organisms/HowToOrderVIdeo';
import { Block } from '@/services/types/blocksTypes';

interface Props {
	data: Block;
	sl?: any;
}

const VideoBlock: React.FC<Props> = ({ data }) => {
	const { block_data, block_machine_name } = data;
	if (block_machine_name === 'how_to_order_in_arogga') {
		return <HowToOrderVideo className='bg-p_bg py-40 mt-40' data={block_data[0]} />;
	}
};

export default VideoBlock;
