import React from 'react';
import Image from 'next/image';
import { getImage } from '@/services/utils/helper';
import Link from 'next/link';

interface Props {
	data: any;
	label: string;
	links?: any;
}
const Image2Column: React.FC<Props> = ({ data, label, links }) => {
	const { children } = links;
	return (
		<div className='container my-30'>
			<div
				className='text-center '
				style={{
					textAlign: 'center',
					marginBottom: 30
				}}>
				<h2 className='text-gray900 text-30 fw-600'>{label}</h2>
			</div>
			<div className='grid grid-cols-2 gap-20'>
				{data?.map((d: any, i: number) => {
					return (
						<div key={i}>
							<Link href={children[i].link} passHref={true}>
								<Image
									className='cursor-pointer'
									alt=''
									src={getImage(d?.file)}
									width={d.width}
									height={d.height}
									style={{
										height: '100%',
										width: '100%'
									}}
									unoptimized
								/>
							</Link>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Image2Column;
