import React from 'react';
import Image from 'next/image';
import { getImage } from '@/services/utils/helper';
import Link from 'next/link';

const HomeBannerImageContainer = ({ data, blockLinks }) => {
	const { bannerLink } = blockLinks;
	return (
		<div
			className='container mb-20'
			style={{
				position: 'relative',
				borderRadius: '10px'
			}}>
			<Link href={bannerLink || '/'}>
				<Image
					src={getImage(data.file)}
					alt='Description'
					height={parseInt(data.height)}
					width={parseInt(data.width)}
					unoptimized={true}
					loading='lazy'
					style={{
						width: '100%',
						height: 'auto',
						borderRadius: '10px'
					}}
				/>
			</Link>
		</div>
	);
};

export default HomeBannerImageContainer;
