import Action2ColumnHorizontal from './Action2ColumnHorizontal';
import ActionHorizontal from './ActionHorizontal';
import ActionSideScrollUnderline from './ActionSideScrollUnderline';
import ActionVertical from './ActionVertical';
import ActionVerticalSideScroll from './ActionVerticalSideScroll';
import ActionsCategorySideScroll from './ActionsCategorySideScroll';
import HealthSupplements from './HealthSupplements';
import MostLoveBrands from './MostLoveBrands';
import MostSearchOnAroggaBeauty from './MostSearchOnAroggaBeauty';
import ShopByCategory from './ShopByCategory';
import SidescrollUpToMoneyRange from './SidescrollUpToMoneyRange';
import TopDeals from './TopDeals';

export {
	Action2ColumnHorizontal,
	ActionHorizontal,
	ActionSideScrollUnderline,
	ActionVertical,
	ActionVerticalSideScroll,
	ActionsCategorySideScroll,
	HealthSupplements,
	MostLoveBrands,
	MostSearchOnAroggaBeauty,
	ShopByCategory,
	SidescrollUpToMoneyRange,
	TopDeals
};
